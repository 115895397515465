<template>
  <div class="mr-3">
    <div class="card">
      <div class="card-body">
        <a-button
          icon="file-pdf"
          type="primary"
          @click="generatePdf"
          :loading="printLoading"
          :disabled="printLoading"
          style="margin-bottom: 20px; float: right"
          >{{ $t("action.imprimer") }}</a-button
        >
        {{ $t("recette.dateEcheance") }}
        <a-range-picker
          style="margin-left: 20px"
          :placeholder="[$t('paiement.dateDebut'), $t('paiement.dateFin')]"
          format="DD/MM/YYYY"
          v-model="dateRangeData"
          :mode="mode"
          @change="
            (val) => {
              handleDateRange(val);
            }
          "
        />
        <br />
        <br />

        <a-table
          :loading="tableLeading"
          :rowKey="'_id'"
          :columns="columns"
          :data-source="data"
          :pagination="true"
          :scroll="{ x: 'max-content' }"
          @change="tableChanged"
        >
          <template slot="name" slot-scope="text">
            <div key="name">
              <template> {{ text }} </template>
            </div>
          </template>
          <template slot="createdAt" slot-scope="text">
            {{ moment(new Date(text)).format("DD/MM/YYYY") }}
          </template>
          <template slot="dateEchance" slot-scope="text">
            <span v-if="text">
              {{ moment(new Date(text)).format("DD/MM/YYYY") }} --
              {{ moment(new Date(text)).fromNow() }}
            </span>
            <span class="text-center" v-else>--</span>
          </template>
          <template slot="versable" slot-scope="text">
            <a-tag :color="text ? 'green' : 'red'">{{
              text ? "Oui" : "Non"
            }}</a-tag>
          </template>
          <template slot="userName" slot-scope="text, record">
            {{ record.transactions[0].userName || "--" }}
          </template>
          <a-button
            slot="details"
            type="primary"
            slot-scope="text, record"
            @click="() => chequeDetailsModal(record)"
          >
            <a-icon type="eye" />{{ $t("punition.details") }}
          </a-button>

          <template slot="tags" slot-scope="text">
            <span v-for="tag in text" :key="tag">
              <a-tag v-if="tag == 'Annulation'" color="red">
                {{ $t("recette.annule") }}
              </a-tag>
              <a-tag v-else-if="tag == 'Avoir'" color="orange">
                {{ $t("paiement.avoir") }}
              </a-tag>
              <a-tag v-else color="blue">
                {{ tag }}
              </a-tag>
            </span>
          </template>

          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>
      </div>
    </div>
    <chequeModal
      v-if="chequeVisible"
      :chequeVisible="chequeVisible"
      :activeCheques="activeCheques"
      @close="handleClose"
    />
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";

import moment from "moment";
import chequeModal from "@/components/comptabilite/chequeModal";
import { pdfHeader } from "@/utilities/pdfUtils";

export default {
  name: "cheques",
  computed: {
    ...mapState(["settings"]),
  },
  components: {
    chequeModal,
  },
  async created() {
    await apiClient
      .get("/cheque/V2/filter")
      .then((res) => {
        this.data = res.data;
        this.rowData = this.data.map((item) => ({ ...item }));
        this.filtredTable = this.data.map((item) => ({ ...item }));
        this.filtredTableCache = this.data.map((item) => ({ ...item }));
        this.tableLeading = false;
      })
      .catch((e) => {
        this.tableLeading = false;
      });
  },
  data() {
    return {
      chequeDetails: {},
      dateRangeData: ["", ""],
      mode: ["date", "date"],
      columns: [
        {
          title: this.$t("recette.dateAjout"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: { customRender: "createdAt" },
          sorter: (a, b) => {
            return (
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
            );
          },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("recette.numero"),
          dataIndex: "num",
          key: "num",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "num",
          },
          onFilter: (value, record) => {
            return record.userName
              ? record.userName.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.banque"),
          dataIndex: "bank",
          key: "bank",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "bank",
          },
          onFilter: (value, record) => {
            return record.bank
              ? record.bank.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.titulaire"),
          dataIndex: "titulaire",
          key: "titulaire",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "titulaire",
          },
          onFilter: (value, record) => {
            return record.titulaire
              ? record.titulaire.toLowerCase().includes(value.toLowerCase())
              : false;
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montant"),
          dataIndex: "montant",
          key: "montant",
          scopedSlots: { customRender: "montant" },
          sorter: (a, b) => {
            return (
              new Date(a.montant).getTime() - new Date(b.montant).getTime()
            );
          },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("sms.tags"),
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            customRender: "tags",
          },
        },
        {
          title: this.$t("recette.dateEcheance"),
          dataIndex: "dateEchance",
          key: "dateEchance",
          scopedSlots: { customRender: "dateEchance" },
          sorter: (a, b) => {
            return (
              new Date(a.dateEchance).getTime() -
              new Date(b.dateEchance).getTime()
            );
          },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Versable ?",
          dataIndex: "versable",
          key: "versable",
          scopedSlots: { customRender: "versable" },
        },
        {
          title: this.$t("recette.doneBy"),
          dataIndex: "userName",
          key: "userName",
          scopedSlots: {
            customRender: "userName",
          },
        },
        {
          title: "Détails",
          dataIndex: "details",
          key: "details",
          fixed: "right",
          scopedSlots: {
            customRender: "details",
          },
        },
      ],

      tableLeading: true,
      chequeVisible: false,
      printLoading: false,
      data: [],
      filtredTable: [],
      filtredTableCache: [],
      rowData: [],
      activeCheques: [],
    };
  },
  methods: {
    handleClose() {
      this.chequeVisible = false;
    },
    chequeDetailsModal(record) {
      let recordData = JSON.parse(JSON.stringify(record));
      this.activeCheques = [];
      this.activeCheques = [recordData];

      const totals = recordData.transactions.reduce(
        (acc, item) => {
          acc.totalCheque += item.montantCheque || 0;
          acc.totalEspece += item.montantEspece || 0;
          return acc;
        },
        { totalCheque: 0, totalEspece: 0 }
      );
      console.log(totals);

      this.activeCheques.montantCheque = totals.montantCheque;
      this.activeCheques.montantEspece = totals.montantEspece;
      this.chequeVisible = true;
    },
    handleDateRange(value) {
      if (value.length == 2) {
        const start = moment(value[0]);
        const end = moment(value[1]);

        this.data = this.rowData.filter((elem) => {
          return moment(elem.dateEchance).isBetween(start, end, "days", "[]");
        });

        this.filtredTable = this.filtredTableCache.filter((elem) => {
          return moment(elem.dateEchance).isBetween(start, end, "days", "[]");
        });
      } else {
        this.data = this.rowData;
        this.filtredTable = this.filtredTableCache;
      }
    },
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
      this.filtredTableCache = extra.currentDataSource;
    },
    generatePdf() {
      this.$gtag.event("Imp Les chèques", {
        event_category: "Impression PDF",
        event_label: "comptabilite:chèques",
        value: 1,
      });

      this.printLoading = true;

      let filterText = "";

      if (this.dateRangeData[0])
        filterText =
          "De " +
          this.dateRangeData[0]._d.toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }) +
          " à " +
          this.dateRangeData[1]._d.toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });

      const doc = pdfHeader(this.settings, null, "Les chèques", filterText);

      const chequeData = [];

      let total = 0;

      this.filtredTable.map((cheque) => {
        total += Number(cheque.montant);
        chequeData.push([
          moment(cheque.createdAt).format("DD/MM/YYYY"),
          cheque.num,
          cheque.bank,
          cheque.titulaire,
          cheque.tags
            .map((tag) => (tag == "Annulation" ? "Annulé" : tag))
            .join(" - "),
          cheque.dateEchance
            ? moment(cheque.dateEchance).format("DD/MM/YYYY")
            : "--",
          cheque.montant,
        ]);
      });

      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri", valign: "middle", halign: "center" },
        headStyles: {
          lineWidth: 0.01,
          lineColor: [0, 0, 0, 0],
        },
        head: [
          [
            "Date d'ajout",
            "Numéro",
            "Banque",
            "Titulaire",
            "Tags",
            "Date d'échéance",
            "Montant",
          ],
        ],
        body: chequeData,
        foot: [
          [
            {
              colSpan: 6,
              content: "Total",
              styles: {
                halign: "center",
                lineWidth: 0.01,
                lineColor: [0, 0, 0, 0],
              },
            },
            {
              content: parseFloat(total).toFixed(2),
              styles: {
                halign: "center",
                lineWidth: 0.01,
                lineColor: [0, 0, 0, 0],
              },
            },
          ],
        ],
        margin: { top: 65 },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 35,
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }

      doc.save("Les chèques.pdf");

      this.printLoading = false;
    },
  },
};
</script>
